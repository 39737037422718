// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery");
require("popper.js");
require("packs/jquery.raty.gaf")
require("controllers")
require("bootstrap4-toggle")

import "bootstrap";
import "@fortawesome/fontawesome-free/css/all"
import "../stylesheets/application_gaf";
import "./timezone";
import "./stripe";
import "./subscriptions";
import "./crew_organizations"
import "select2";
import "@fortawesome/fontawesome-free/js/all";
import $ from 'jquery';
import flatpickr from "flatpickr";
import moment from "moment";
import { AsYouType } from 'libphonenumber-js'
import "./trackingEvents";
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

window.addEventListener('turbolinks:load', () => {
  $('.tag-tooltip').tooltip();

  $('[data-toggle="offcanvas"]').on('click', function () {
    $($(this).data('target')).toggleClass('open');
    $('#crew_search_container').toggleClass('o-hidden');
  });

  // LM-423 this script are comment for sector and roof type fields are created dynamically
  // $('select.select2ize').each( function() {
  //   $(this).select2({
  //     theme: 'bootstrap'
  //   });
  // });

   // this below 53 to 62 added for lm-423
   $('select.select2ize').each( function() {                                    
    if(!$(this).hasClass('select2-hidden-accessible')){
      $(this).select2({
        theme: 'bootstrap'
      });
    }else{
      window.location.reload(true);
    }
   });  
  $('#resend_confirmation').on('ajax:success', ({ detail }) => {
    $('#resend_confirmation_text').text(detail[0].message);
    $('#resend_confirmation').text('');
  });

  $('.daterangepicker').each(function (i, el) {
    var _el = $(el)
    var startDate = _el.data('start-date'),
        maxDate = _el.data('max-date'),
        startEl = _el.data('start-el'),
        endEl = _el.data('end-el');

    el.flatpickr({
      mode: "range",
      defaultDate: [startDate, maxDate],
      maxDate: maxDate,
      onChange: function (selectedDates, dateStr, instance) {
        $(startEl).val(moment(selectedDates[0]).format('YYYY-MM-DD'));
        if (selectedDates.length > 1) {
          $(endEl).val(moment(selectedDates[1]).format('YYYY-MM-DD'));
        }
      }
    });
  });

  $('.scroll-bottom').each(function(i, el) {
    $(el).scrollTop($(el).prop('scrollHeight'));
  });

  // Message file attachments
  $('.add-attachment').click(function() {
    $(this).siblings('.hidden-attachment')[0].click();
  });
  $('.hidden-attachment').change(function() {
    var fileCount = this.files.length;
    var badge = $(this).siblings('.attachment-info').find('.badge');
    if (fileCount > 1) {
      badge.removeClass('d-none');
      badge.text(`${fileCount} files`);
    }
    else if (fileCount > 0) {
      badge.removeClass('d-none');
      badge.text('1 file');
    }
    else {
      badge.addClass('d-none');
      badge.text('');
    }
  });

  $('.rating-profile').empty();
  $('.rating-profile').raty({
    path: '/img/',
    readOnly:   true,
    score: function () { return $(this).attr("data-score"); }
  });

  $('#select-decking-type').change(function(e) {
    if($('#select-decking-type').find(":selected").text()==='Other'){
      $('#decking-type-other-field').show();
    } else {
      $('#decking-type-other-field').hide();
    }
  });

  $('#crew_organization_attributes_member_count_other').click(function(e) {
    $('#member-count-other-field-new').show();
    $('#member-count-other-field-edit').show();
  });

  $('#crew_organization_attributes_member_count_ten').click(function(e) {
    $('#member-count-other-field-new').hide();
    $('#member-count-other-field-edit').hide();
  });

  $('#crew_organization_attributes_member_count_five').click(function(e) {
    $('#member-count-other-field-new').hide();
    $('#member-count-other-field-edit').hide();
  });

  $('#crew_organization_attributes_member_count_three').click(function(e) {
    $('#member-count-other-field-new').hide();
    $('#member-count-other-field-edit').hide();
  });

  $("#crew_organization_attributes_insurance_informations_other").change(function() {
    if(this.checked) {
      $('#insurance-information-other-field-new').show();
      $('#insurance-information-other-field-edit').show();
    } else {
      $('#insurance-information-other-field-new').hide();
      $('#insurance-information-other-field-edit').hide();
    }
  });

  $('#job_insurance_required_true').change(function(){
    if ($('#job_insurance_required_true').is(':checked')) {
      $('#insurance_required_details').show();
    }
  });

  $('#job_insurance_required_false').change(function(){
    if ($('#job_insurance_required_false').is(':checked')) {
      $('#insurance_required_details').hide();
    }
  });

  $('#job_detailed_pictures').change(function(){
    var label = $(this).parent( ".custom-file" ).find( "label" )
    label.text('').append('<div></div>');
    Array.from(this.files).forEach(({name}) => {
      label.find('div').append(`<span class="bording-file-input">${name}</span>`);
    });
  });


  $('#job_scope_files').change(function(){
    var label = $(this).parent( ".custom-file" ).find( "label" )
    label.text('').append('<div></div>');
    Array.from(this.files).forEach(({name}) => {
      label.find('div').append(`<span class="bording-file-input">${name}</span>`);
    });
  });

  $('#bid_recent_project_pictures').change(function(){
    var label = $(this).parent( ".custom-file" ).find( "label" )
    label.text('').append('<div></div>');
    Array.from(this.files).forEach(({name}) => {
      label.find('div').append(`<span class="bording-file-input">${name}</span>`);
    });
  });

  $('#crew_organization_attributes_project_photos').change(function(){
    var label = $(this).parent( ".custom-file" ).find( "label" )
    label.text('').append('<div></div>');
    Array.from(this.files).forEach(({name}) => {
      label.find('div').append(`<span class="bording-file-input">${name}</span>`);
    });
  });

  //crew rating stars applying
  $('#crew-overall-rating').raty({
    path: '/img/',
    scoreName: 'crews_rating[overall_rating]'
  });
  $('#crew-quality-rating').raty({
    path: '/img/',
    scoreName: 'crews_rating[quality_of_work]'
  });
  $('#crew-safety-rating').raty({
    path: '/img/',
    scoreName: 'crews_rating[safety]'
  });
  $('#crew-punctuality-rating').raty({
    path: '/img/',
    scoreName: 'crews_rating[punctuality]'
  });
  $('#crew-jobsite-cleanliness-rating').raty({
    path: '/img/',
    scoreName: 'crews_rating[jobsite_cleanliness]'
  });

  //contractor rating stars applying
  $('#contractor-overall-rating').raty({
    path: '/img/',
    scoreName: 'contractors_rating[overall_rating]'
  });
  $('#contractor-communication-rating').raty({
    path: '/img/',
    scoreName: 'contractors_rating[communication]'
  });
  $('#contractor-accurate-scope-work-rating').raty({
    path: '/img/',
    scoreName: 'contractors_rating[accurate_scope_of_work]'
  });
  $('#contractor-timely-payment-rating').raty({
    path: '/img/',
    scoreName: 'contractors_rating[timely_payment]'
  });

  $('.modal.show').modal('show');

  $('input[name="job[close_reason]"]').change(function() {
    var val = $(this).val();
    if (val == 'specified') {
      $('#job_close_reason_other').removeClass('d-none');
    }
    else {
      $('#job_close_reason_other').addClass('d-none');
    }
  });

  $('input.phone').on('change paste keyup blur', (e) => {
    e.target.value = new AsYouType('US').input(e.target.value)
  });
  
  var coll = document.getElementsByClassName("advance_search_collapsible");
  var i;

  for (i = 0; i < coll.length; i++) {
    coll[i].addEventListener("click", function() {
      this.classList.toggle("active");
      var content = this.nextElementSibling;
      if (content.style.display === "block") {
        content.style.display = "none";
      } else {
        content.style.display = "block";
      }
    });
  }

  $(".see_result_btn").on("click",function(){
    $("#mobile_search").val("true")
  })

  $("#direct_message_read_more").on("click",function(){
    if (($("#direct_message_span")[0]).style.display === "none") {
      ($("#direct_message_span")[0]).style.display = "inline";
      ($("#direct_message_read_more")[0]).innerHTML = "Read Less";
    } else {
      ($("#direct_message_span")[0]).style.display = "none";
      ($("#direct_message_read_more")[0]).innerHTML = "Read More";
    }
  })

  $("#project_message_read_more").on("click",function(){
    if (($("#project_message_span")[0]).style.display === "none") {
      ($("#project_message_span")[0]).style.display = "inline";
      ($("#project_message_read_more")[0]).innerHTML = "Read Less";
    } else {
      ($("#project_message_span")[0]).style.display = "none";
      ($("#project_message_read_more")[0]).innerHTML = "Read More";
    }
  })

  $("#q_state_eq").on("change",function(){
    $("#q_states_serviced_cont").val("")
  })

  $("#q_states_serviced_cont").on("change",function(){
    $("#q_state_eq").val("")
  })

  $(".close_profile_note").on("click",function(){
    $(".update_profile_note").css("display","none")
    document.cookie = "cross_icon=false";
  })

  $(function() {
    var readURL = function(input) {
      if (input.files || input.files[0]){
        var reader = new FileReader();
  
        reader.onload = function (e) {
        $('.profile-pic').attr('src', e.target.result);
        }
  
        reader.readAsDataURL(input.files[0]);
      }
    };
  
    $(".file-upload").on('change', function(){
      readURL(this);
    });
  
    $(".upload-button").on('click', function() {
      $(".file-upload").click();
    });
  });

  
  $("body").on('click touchstart', '.password_toggle', function() {
    $(this).toggleClass("fa-eye fa-eye-slash");
    var input = $(".password_eye");
    if (input.attr("type") === "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }
  
  });

  $("body").on('click touchstart', '.password_toggle_c', function() {
    $(this).toggleClass("fa-eye fa-eye-slash");
    var input = $(".password_eye_c");
    if (input.attr("type") === "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }
  
  });
  $("body").on('click touchstart', '.password_toggle_o', function() {
    $(this).toggleClass("fa-eye fa-eye-slash");
    var input = $(".password_eye_o");
    if (input.attr("type") === "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }
  
  });

  $('document').ready(function() {
    setTimeout(function() {
      $('.alert-success').slideUp();
    }, 5000);
  });

  // $(document).ready(function () {    
  //   $('#contractor_phone, #contractors_user_organization_attributes_phone, #contractors_user_phone').keypress(function (e) {    
  //       var charCode = (e.which) ? e.which : event.keyCode    
  //       if (String.fromCharCode(charCode).match(/[^0-9]/g))    
  //       return false;                        
  //   });       
  // }); 

  $(document).ready(function() {
    var textField = $("#contractor_phone, .phont, #contact_request_phone, #contractor_phone, #contractors_user_phone, #crew_phone");
    var outputVal;
  
    textField.on("input", function(e) {
      mobile_number_format(e, textField, true)
    });

    var contractor_field = $("#contractors_user_organization_attributes_phone, #crew_organization_attributes_phone")
    contractor_field.on("input", function(e) {
      mobile_number_format(e, contractor_field, false)
    });

    function mobile_number_format(e, textField, flag){
      var currentValue = textField.val().replace("+1", "");
      if(currentValue.match("^1")){
        currentValue = currentValue.replace("1", "")
      }
      var numericValue = currentValue.replace(/\D/g, "");
      var formattedValue = numericValue.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      if (e.originalEvent.inputType === "deleteContentBackward") {
        if(flag){textField.val(numericValue)}
      } else {
        if (e.originalEvent.inputType === "insertFromPaste") {
          if( formattedValue.match("^1")){
            outputVal = outputVal
          }else{
            outputVal = "+1" + formattedValue;
          }
        }else{
          if (numericValue.length > 10) {
            numericValue = numericValue.substr(0, 10);
          }
          outputVal = "+1" + formattedValue;
        }
        textField.val(outputVal);
      }
    }
  });
});

